import React, {useEffect,useState} from "react"
import Navbar from "../components/Navbar"
import { graphql, GatsbyImage, navigate } from "gatsby"

const encode = (data) => {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
}

const fakefetch = async (url,data) => {
  console.log(data);
}

const handleChange = () => {}

const ContactPage = () => {
  let [submitted,setSubmitted] = useState(0); //1 is success, -1 is failure
  const handleSubmit = (event) => {
    let form = event.target;
    let formData = new FormData(form);
    console.log({
      form,
    formData
    })
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setSubmitted(1))
      .catch((error) => setSubmitted(-1));
  };
  return (
    <form id="contact-form" data-netlify="true" name="contact"
    method="POST" onSubmit={handleSubmit}
    action="/pages/success"
    >
      <h1>Get in touch with RGB Systems!</h1>

      <input type="hidden" name="form-name" value="contact" />

      <div className="question">
        <label htmlFor="Name">Your name:</label>
        <input type="text" id="name" name="name"></input>
      </div>
      <div className="question">
        <label htmlFor="email">Your email:</label>
        <input type="text" id="email" name="email"></input>
      </div>
      <div className="question">
        <label htmlFor="business">Business name, if applicable:</label>
        <input type="text" id="business" name="business"></input>
      </div>
      <div className="question">
        <label htmlFor="url">URL, if applicable:</label>
        <input type="text" id="url" name="url"></input>
      </div>

      <div className="question textarea">
        <label htmlFor="describe">Please describe the project in a few sentences.</label>
        <textarea type="textarea" rows="4" columns="10" name="describe"></textarea>
      </div>
      <div className="question checkboxes">
        <label className="instructions">Please check the options that apply. We won’t hold you to your responses if and when we work together.</label>
        <fieldset id="deadline"><legend>Does your project have a set deadline?</legend>
          <div>
            <label htmlFor="i1">Yes</label>
            <input id="i1" type="radio" name="deadline" value="Yes"></input>
          </div>
          <div>
            <label htmlFor="i2">More information is needed; then a deadline will be set</label>
            <input id="i2" type="radio" name="deadline" value="More information is needed; then a deadline will be set"></input>
          </div>
          <div>
            <label htmlFor="i3">No</label>
            <input id="i3" type="radio" name="deadline" value="No"></input>
          </div>

        </fieldset>
        <fieldset id="budget"><legend>Does your project have a set budget?</legend>
          <div>
            <label htmlFor="i4">Yes</label>
            <input id="i4" type="radio" name="budget" value="Yes"></input>
          </div>
          <div>
            <label htmlFor="i5">More information is needed; Then a budget will be set</label>
            <input id="i5" type="radio" name="budget" value="More information is needed; Then a budget will be set"></input>
          </div>
          <div>
            <label htmlFor="i6">No</label>
            <input id="i6" type="radio" name="budget" value="No"></input>
          </div>

        </fieldset>
        <fieldset id="project"><legend>Your project is in which stage?</legend>
          <div>
            <label htmlFor="i7">Starting from the beginning.</label>
            <input id="i7" type="radio" name="project-progress" value="Starting from the beginning."></input>
          </div>
          <div>
            <label htmlFor="i8">Some design has been done.</label>
            <input id="i8" type="radio" name="project-progress" value="Some design has been done."></input>
          </div>
          <div>
            <label htmlFor="i9">Software/tools are already set up for the project.</label>
            <input id="i9" type="radio" name="project-progress" value="Software/tools are already set up for the project."></input>
          </div>
          <div>
            <label htmlFor="i10">We want to make some changes to a project that’s already been published/launched.</label>
            <input id="i10" type="radio" name="project-progress" value="We want to make some changes to a project that’s already been published/launched."></input>
          </div>

        </fieldset>
      </div>
      <div className="question textarea">
        <label htmlFor="other">Any other notes you’d like to add?</label>
        <textarea type="textarea" rows="4" id="other" name="other"></textarea>
      </div>
      {submitted === 0 ? <div className="form-submit"><input type="submit" /></div> :
      submitted === -1 ? <h1 className="form-result">Error :(</h1> : <h1 className="form-result">Success! We'll get back to you soon!</h1>}
    </form>
  )
}

//Thanks so much for your interest in working together! We’ll get back to you soon.

//TODO tags with spaces

// export const data = graphql`
//   query headQuery {
//     strapiHeader {
//       Navigation {
//         Title
//         Url
//       }
//       Logo {
//         url
//       }
//     }
//   }
// `

export default ContactPage
